// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-work-agave-js": () => import("./../../../src/pages/work/agave.js" /* webpackChunkName: "component---src-pages-work-agave-js" */),
  "component---src-pages-work-fruitsmith-js": () => import("./../../../src/pages/work/fruitsmith.js" /* webpackChunkName: "component---src-pages-work-fruitsmith-js" */),
  "component---src-pages-work-gym-js": () => import("./../../../src/pages/work/gym.js" /* webpackChunkName: "component---src-pages-work-gym-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-karuneshwari-js": () => import("./../../../src/pages/work/karuneshwari.js" /* webpackChunkName: "component---src-pages-work-karuneshwari-js" */),
  "component---src-pages-work-stimulate-js": () => import("./../../../src/pages/work/stimulate.js" /* webpackChunkName: "component---src-pages-work-stimulate-js" */),
  "component---src-pages-work-tamasha-js": () => import("./../../../src/pages/work/tamasha.js" /* webpackChunkName: "component---src-pages-work-tamasha-js" */),
  "component---src-pages-work-thelightear-js": () => import("./../../../src/pages/work/thelightear.js" /* webpackChunkName: "component---src-pages-work-thelightear-js" */),
  "component---src-pages-work-thelighthouse-js": () => import("./../../../src/pages/work/thelighthouse.js" /* webpackChunkName: "component---src-pages-work-thelighthouse-js" */),
  "component---src-pages-work-yogsattva-js": () => import("./../../../src/pages/work/yogsattva.js" /* webpackChunkName: "component---src-pages-work-yogsattva-js" */)
}

